.card-1 {
     margin-top: 2rem;
     width: 30%;
     box-shadow: 0px 0px 10px #00000029;
     border-radius: 9px;
     border: none;
     opacity: 1;
     margin-left: 58rem;
}

.add-btn {
     margin-left: 4rem;
     background-color: #E5593C !important;
     border: none;
     border-radius: 2rem;
     padding-right: 1.3rem;
     margin-top: 3px;
     margin-bottom: 3px;
}

.pipeline-table {
     margin-top: 3rem !important;
     width: 88% !important;
     margin-top: 3rem;
     margin: auto;
     color: red;
}

// .pagination {
//      margin-top: 3rem;
//      justify-content: end;
//      margin-right: 5.5rem;
//      gap: 1rem;
//      margin-bottom: 5rem;
// }

// .box-icon {
//      gap: 2rem;
// }