.navbar-header {
     background-color: #203057;
     height: 5.4rem;
     border-bottom: 2px solid #56829A;
}

.navbar-menu {
     background-color: #203057;
     // border-top: 2px solid #56829A;
     // #69EEFF
}

.data-obs-btn {
     border: 1px solid #69EEFF !important;
     color: #ffffff !important;
     margin-left: 1rem;
}

.navbar-menu {
     height: 4.7rem;
}

.link1 {
     color: #ffffff !important;
     font-weight: bold !important;
     font-size: 1rem !important;
}

.link2 {
     color: #ffffff !important;
     font-weight: bold !important;
     font-size: 1rem !important;
}

.link3 {
     color: #ffffff !important;
     font-weight: bold !important;
     font-size: 1rem !important;
}

.link4 {
     color: #ffffff !important;
     font-weight: bold !important;
     font-size: 1rem !important;
}

#drop-1\ basic-nav-dropdown {
     color: #ffffff !important;
     font-weight: bold !important;
     font-size: 1rem !important;
}

.user-name {
     color: #ffffff !important;
     font-size: 0.9rem !important;
     margin-left: 0.3rem;
}